import { BreakPoint } from './index'

const size = {
	xxsm: { mob:`1.2rem`, desk:`1.4rem` },
	xsm: { mob:`1.4rem`, desk:`1.6rem` },
	sm: { mob:`1.8rem`, desk:`2rem` },
	md: { mob:`2rem`, desk:`2.5rem` },
	lg: { mob:`2.5rem`, desk:`3rem` },
	xl: { mob:`3rem`, desk:`3.5rem` },
	xxl: { mob:`4rem`, desk:`4.4rem` },
	xxxl: { mob:`10vw`, desk:`8rem` },
}
export const FontSize = {
	xxsm: `
		font-size: ${size.xxsm.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.xxsm.desk};
		}`,
	xsm: `
		font-size: ${size.xsm.mob};
		line-height: 140%;
		letter-spacing: 0.03rem;
		@media ${BreakPoint.md} {
			font-size: ${size.xsm.desk};
		}`,
	sm: `
		font-size: ${size.sm.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.sm.desk};
		}`,
	md: `
		font-size: ${size.md.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.md.desk};
		}`,
	lg: `
		font-size: ${size.lg.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.lg.desk};
		}`,
	xl: `
		font-size: ${size.xl.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.xl.desk};
		}`,
	xxl: `
		font-size: ${size.xxl.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.xxl.desk};
		}`,
	xxxl: `
		font-size: ${size.xxxl.mob};
		line-height: 140%;
		@media ${BreakPoint.md} {
			font-size: ${size.xxxl.desk};
		}`,
}
