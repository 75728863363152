import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import styled from 'styled-components'

import { Footer, Header, Nav, Social } from "./"

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`
const Main = styled.main`
	flex-grow: 1;
`

export const Page = ({ children }) => {

	return (

		<Container>
			<Header/>
			<Nav/>
			<Main>
				{ children }
			</Main>
			<Social/>
			<Footer/>
		</Container>

	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
