import styled from 'styled-components'
import { FontSize } from '../variables/'

export const TextContent = styled.div`
	color: ${props => props.colour ? props.colour : "inherit"};
	text-align: ${props => props.center ? "center" : "inherit"};
	width: 100%;
	> * {
		margin-bottom: 0;
		&:last-child {
			margin-bottom: 0!important;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}
	h1, h2, h3, h4, h5, h6, p, ul, ol {
		margin-bottom: 20px;
	}
	h1 {
		${FontSize.xxl}
	}
	h2 {
		${FontSize.xl}
		margin-top: 30px;
		&:first-child {
			margin-top: 0;
		}
	}
	h3 {
		${FontSize.lg}
		margin-top: 30px;
		&:first-child {
			margin-top: 0;
		}
	}
	h4 {
		${FontSize.md}
	}
	h5, h6, p, ul, th, td {
		${FontSize.xsm}
	}
	ul {
		list-style-type: disc;
		margin-left: 20px;
	}
	i {
		display:inline-block;
	}
`
